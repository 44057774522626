import { Controller } from "@hotwired/stimulus";

export default class FileInputController extends Controller {
  static targets = ["value", "input"];

  open() {
    this.inputTarget.click();
  }

  display(event) {
    const fileName = event.target.value.split("\\").pop() || "No file selected";

    if (this.valueTarget.nodeName == "INPUT") {
      this.valueTarget.placeholder = fileName;
    } else {
      this.valueTarget.innerHTML = fileName;
    }
  }
}
