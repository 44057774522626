import { Controller } from "@hotwired/stimulus";
import { Veriff } from "@veriff/js-sdk";
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";

export default class extends Controller {
  static values = {
    apiKey: String,
    userId: String,
    sessionId: String,
    sessionUrl: String,
  };

  connect() {
    this.veriff = Veriff({
      host: "https://stationapi.veriff.com",
      apiKey: this.apiKeyValue,
      onSession: this.handleSession.bind(this),
      parentId: "verify",
    });

    if (this.sessionIdValue) {
      this.createFrame(this.sessionUrlValue);
    } else {
      this.createForm();
    }
  }

  createFrame(url) {
    createVeriffFrame({
      url,

      onEvent: (msg) => {
        switch (msg) {
          case MESSAGES.CANCELED:
            Turbo.visit('/profile');
            break;

          case MESSAGES.FINISHED:
            this.updateVerification({ status: 'processing_by_veriff' }).then(
              () => {
                Turbo.visit('/');
              },
            );
            break;
        }
      },
    });
  }

  createForm() {
    this.veriff.setParams({ vendorData: this.userIdValue });

    this.veriff.mount({
      formLabel: {
        givenName: "First name",
        lastName: "Last name",
      },
      submitBtnText: "Verify Your Account",
      loadingText: "Please wait...",
    });
  }

  updateVerification(params) {
    const data = new FormData();

    Object.entries(params).forEach(([key, value]) => {
      data.append(key, value);
    });

    return fetch(`/profile/verification`, {
      method: "PUT",
      body: data,
      headers: {
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
    });
  }

  handleSession(err, response) {
    if (err) {
      console.error("Veriff session error:", err);
      return;
    }
    this.updateVerification({
      veriff_session_url: response.verification.url,
      veriff_session_id: response.verification.id,
    });
    this.createFrame(response.verification.url);
  }
}
