import { Controller } from "@hotwired/stimulus";

export default class VideoFormController extends Controller {
  static targets = ["fileInput", "progress", "progressBar", "submitButton"];

  connect() {
    document.addEventListener("direct-upload:progress", (e) => {
      if (e.target.id === this.fileInputTarget.id) {
        this.progressBarTarget.style.width = `${e.detail.progress}%`;
      }
    });
  }

  toggleSubmit(e) {
    if (e.target.value) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  submit() {
    this.progressTarget.classList.remove("d-none");
  }
}
