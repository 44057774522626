import { Controller } from "@hotwired/stimulus";

export default class FlashMessagesController extends Controller {
    static targets = ["alert"];

    connect() {
        this.initAlerts();
    }

    initAlerts() {
        this.alertTargets.forEach(alert => {
            setTimeout(() => {
                alert.classList.remove('show');
                setTimeout(() => alert.remove(), 1500);
            }, 3000);
        });
    }

    removeAlert(event) {
        const alert = event.currentTarget;
        alert.classList.remove('show');
        setTimeout(() => alert.remove(), 1500);
    }
}
